<script>
    import { onMount } from "svelte";
    import { localeRoute, getLanguage, lang, url, toggleOverflowPage, paths } from "../utils/api";
    import Logo from "./Logo.svelte";
    // import SwitchLanguage from "./SwitchLanguage.svelte";

    let mobMenuOpen = false;
    let homePage = localeRoute + '/';
    const icons = fetch(paths.lang+'icons.svg').then(response=>{return response.text()});

    onMount(()=>{
        const slug = url.withoutLang;
        if(
            slug == '' ||
            slug == '/'
        ){
            homePage = '#brusko';
        }
    })

    $: toggleOverflowPage(mobMenuOpen);
</script>
<div class="header" class:bg={mobMenuOpen}>
    <a 
        href="{homePage}" 
        class="logo"
        on:click={()=>{mobMenuOpen = false}}
    ><Logo/></a>
    <nav class="menu" class:open={mobMenuOpen}>
        {#await getLanguage('main') then translate}
            <a href="{localeRoute}/about">{translate.menu.Company}</a>
            <a href="{localeRoute}/products">{translate.menu.Products}</a>
            <!-- <a href="{localeRoute}/news">{translate.menu.News}</a> -->
            <a href="{localeRoute}/contacts">{translate.menu.Contacts}</a>
        {/await}
    </nav>
    <div class="lang">
        <!-- <SwitchLanguage /> -->
    </div>
    <button 
        class="hamburger" 
        on:click={()=>{mobMenuOpen = !mobMenuOpen}}
        class:active={mobMenuOpen}
    >
        <svg viewBox="0 0 100 100" width="50" height="50">
            <path class="top" d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
            <path class="middle" d="m 30,50 h 40" />
            <path class="bottom" d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
        </svg>    
    </button>
</div>

{#await icons then svg}
    {@html svg}
{/await}
<style lang="postcss">
    .header{
        position: fixed 0 0 *;
        width: 100%;
        z-index: 50;
        
        background: linear-gradient(180deg, #060606 55.62%, rgba(6, 6, 6, 0.76) 100%);
        /* background: linear-gradient(180deg, #2229 55.62%, #2226 100%); */
        transition: background .3s ease-out;
        display: flex;
        margin: * auto;
        align-items: center;
        padding: 8px 20px;
        
        &{
            @media (min-width:992px) {
                /* padding: 37px *; */
                /* justify-content: space-between; */
                padding: 20px *;
                background: linear-gradient(180deg, #2229 55.62%, #2226 100%);
                border-radius: 30px;
                margin: 10px auto;
                max-width: calc(100vw - 32px);
                backdrop-filter: blur(20px);
            }
            @media (min-width:1200px) {
                padding: * 72px;
            }
        }
        &:after{
            transform: translate(-100%);
            content: '';
            size: 100%;
            position: absolute 0;
            z-index: -1;
            background: var(--bg);
            transition: .3s ease-out;
            @media (min-width:992px) {
                display: none;
            }
        }
        &.bg:after{
            transform: translate(0%);
        }
        & .logo{
            display: block;
            max-width: 124px;
            max-height: 42px;
        }
        & .menu{
            font-weight: 700;

            @media (max-width:991px) {
                position: absolute 70px 100% * *;
                transition: transform .3s ease-out;
                size: 100% calc(100vh - 70px);
                padding-top: 42px;

                /* background: var(--bg); */
                &.open{
                    transform: translateX(100%);
                }
            }
            @media (min-width:992px){
                margin: * * * auto;
            }
            & a{
                display: block;
                margin: 10px 0;
                padding: 10px 20px;
                font-size: 24px;
                line-height: 1;
                text-transform: uppercase;
                @media (min-width:992px) {
                    font-family: inherit;
                    display: inline;
                    margin: 0 10px;
                    font-size: 1rem;
                    border-radius: 10px;
                    padding: 8px 31px;
                    border: 1px solid #0000;
                    transition: .3s ease-out;
                    &:hover{
                        color: var(--primary);
                        border-color: var(--primary);
                    }
                    &:last-child{
                        margin: * 0 * *;
                    }
                }
                @media (min-width:1280px){
                    margin: 0 20px;
                }
                
            }
        }
    }
    /*rtl:begin:ignore*/
    .lang{
        margin: * * * auto;
        min-width: 106px;
    }
    .hamburger{
        background: none;
        cursor: pointer;
        border: none;
        padding: 0;
        margin-right: -15px;

        &{
            @media (min-width:992px) {
                display: none;
            }
        }

        & svg{
            -webkit-tap-highlight-color: transparent;
            transition: transform 400ms;
            user-select: none;
        }
        & path{
            fill: none;
            transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
            stroke: var(--primary);
            stroke-width: 5.5;
            stroke-linecap: round;
        }
        &.active{
            transform: rotate(45deg);
        }
        & .top{
            stroke-dasharray: 40 160;
        }
        & .middle{
            stroke-dasharray: 40 142;
            transform-origin: 50%;
            transition: transform 400ms;
        }
        & .bottom{
            stroke-dasharray: 40 85;
            transform-origin: 50%;
            transition: transform 400ms, stroke-dashoffset 400ms;
        }
        &.active .top{
            stroke-dashoffset: -64px;
        }
        &.active .middle{
            transform: rotate(90deg);
        }
        &.active .bottom{
            stroke-dashoffset: -64px;
        }
    }
    /*rtl:end:ignore*/
</style>