<script>
    import Logo from "./Logo.svelte";

</script>
<div class="container">
    <hr />
    <div id="footer">
        <div class="logo">
            <a href="/">
                <Logo />
            </a>
            <p>
                LLC BRUSKO FACTORY
            </p>
        </div>
        <div class="copyright">
            ©BRUSKOCORP all rights reserved
        </div>
        <a href="https://www.freeprivacypolicy.com/live/7d993a31-4dc5-423b-aea0-f0572fb07951" target="_blank" rel="nofollow">Privacy Policy</a>
    </div>
</div>

<style lang="postcss">
    #footer{
        position: relative;
        z-index: 5;
        line-height: 1;
        padding: 33px *;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        & .container{
        }
        & .logo{
            width: 100%;
            margin: * * 20px;
            line-height: 1.5;

            @media (min-width:992px){
                width: 180px;
                margin: * * 0;
            }

            & :global(svg){
                max-width: 125px;
                flex: 0 0 125px;
            }
            & p{
                margin: 12px 0 0;
            }
        }
        & .copyright{
            flex: 1 1 50%;
            @media (min-width:992px){
                flex: 1 1 auto;
                margin: * 92px;
                text-align: right;
            }
        }
        & .privacy{
            flex: 1 1 50%;
            
            @media (min-width:992px){
                flex: 1 1 auto;
            }
        }
    }
    hr{
        border:none;
        border-top: 1px solid #3D3D3D;
    }
</style>