import Logo from "./lib/component/Logo.svelte";
import SocialLinks from "./lib/component/SocialLinks.svelte";
import Header from "./lib/component/Header.svelte";
import Footer from "./lib/component/Footer.svelte";
import { crawlerBot, referrer } from "./lib/utils/stores/states";

// @ts-ignore
window.lang = document.body.getAttribute('data-lang');

if(!crawlerBot){
    import('./lib/component/Preloader.svelte').then(res=>{
        const Preloader = res.default;
        new Preloader({
            target: document.getElementById("app"),
        });
    });
    // import('./lib/utils/prerender').then(res=>{
    //     res.prerenderInit();
    // })
}else{
    document.body.classList.add('crawlerBot');
}
const acceptAgeOpen = !referrer;

if(acceptAgeOpen && !crawlerBot){
    import('./lib/component/AcceptAge.svelte').then(
        res=>{
            const AcceptAge = res.default;
            new AcceptAge({
                target: document.getElementById("app"),
                props:{
                    open: acceptAgeOpen
                }
            });
        }
    );
}

const header = new Header({
    target: document.getElementById("s-header"),
});
const footer = new Footer({
    target: document.getElementById("s-footer"),
});

document.querySelectorAll('.s-logo').forEach(el=>{
    new Logo({
        target: el,
    })
});
document.querySelectorAll('.s-social').forEach(el=>{
    new SocialLinks({
        target: el,
    })
});

function scrollTop(){
    const hash = window.location.hash; 
    if(!hash || hash ==''){
        document.body.scroll(0,0);
        window.scroll(0,0);
    }
}

document.addEventListener('DOMContentLoaded', scrollTop);

window.addEventListener('load',()=>{
    // document.querySelectorAll('.onload').forEach(el=>{
    //     const timer = setTimeout(()=>{
    //         el.classList.remove('onload');
    //         clearTimeout(timer);
    //     }, 1000);
    // });
    scrollTop();
    // document.body.classList.add('loaded');
});

export default {header};
