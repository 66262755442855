<script>
    // import telegramIcon from '../../../img/icons/tg.svg?raw';
    // import tikTokIcon from '../../../img/icons/tt.svg?raw';
    import instagramIcon from '../../../img/icons/inst.svg?raw';
    // import facebookIcon from '../../../img/icons/fb.svg?raw';
    import twitterIcon from '../../../img/icons/twit.svg?raw';
</script>

<!-- <a href="/#" class="fbLink">
    {@html facebookIcon}
</a> -->
<a href="https://twitter.com/BruskoGlobal"  target="_blank" rel="nofollow noopener noreferrer" class="twitLink">
    {@html twitterIcon}
</a>
<a href="https://instagram.com/brusko_global?igshid=MzRlODBiNWFlZA=="  target="_blank" rel="nofollow noopener noreferrer" class="instLink">
    {@html instagramIcon}
</a>
<!-- <a href="/#" class="ttLink">
    {@html tikTokIcon}
</a>
<a href="/#" class="tgLink">
    {@html telegramIcon}
</a> -->
